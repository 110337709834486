import React from "react"

function Map() {
  return (
    <div>
      <iframe
        style={{ width: "100%", height: "450px", border: "none" }}
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10605.457466277707!2d11.1390327!3d48.3535218!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x26874bf5de381821!2sMichael%20Schneider!5e0!3m2!1sde!2sde!4v1570446602706!5m2!1sde!2sde"
        title="Google Maps"
        allowfullscreen
      />
    </div>
  )
}

export default Map
