import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import ContactBlock from "../components/contact-block"
import Map from "../components/map"

const Margins = styled.div`
  max-width: 1060px;
  margin: 0 auto;
`

export default () => (
  <Layout>
    <Margins>
      <ContactBlock />
      <Map />
    </Margins>
  </Layout>
)
